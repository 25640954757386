import cn from 'classnames';
import {
  BREAKPOINTS_SIZE_SMALL_TO_BIG,
  ScreenBreakpoint,
  useScreenBreakpoint
} from '@gemini/shared/ui/utils/hooks';
import { IVirtualGridProps, VirtualGrid } from './VirtualGrid';

export type BreakpointValues<T> = {
  [key in ScreenBreakpoint]: T;
};
export type BreakpointOptions<T> = Partial<BreakpointValues<T>>;

export type BreakpointParam<T> = BreakpointOptions<T> | T;

export interface IResponsiveVirtualGridProps<T extends HTMLElement> {
  count: number;

  cols: BreakpointParam<number>;
  rowsPerCell?: BreakpointParam<number>;
  overscanCells?: BreakpointParam<number>;
  defaultSize?: BreakpointParam<number>;
  classNames?: BreakpointParam<string>;

  children: IVirtualGridProps<T>['children'];

  className?: string;
}

type BreakpointDefaults<T> = {
  options?: BreakpointOptions<T>;
  default: T;
};

const isOptions = <T,>(obj: any): obj is BreakpointOptions<T> => {
  if (obj && typeof obj === 'object') {
    return true;
  }

  return false;
};

const isValue = <T,>(obj: any): obj is T => {
  return obj !== null && typeof obj !== 'undefined';
};

const getSmallestFromOptions = <T,>(
  bp: ScreenBreakpoint,
  options: BreakpointOptions<T>
): T | undefined => {
  const bpIdx = BREAKPOINTS_SIZE_SMALL_TO_BIG.indexOf(bp);
  for (let idx = bpIdx - 1; idx >= 0; idx--) {
    const smallerBp = BREAKPOINTS_SIZE_SMALL_TO_BIG[idx];
    const bpValue = options[smallerBp];
    if (isValue(bpValue)) {
      return bpValue as T;
    }
  }

  return undefined;
};

const getParamValue = <T,>(
  bp: ScreenBreakpoint,
  options: BreakpointParam<T> | undefined,
  defaults: BreakpointDefaults<T>
): T => {
  const usingOptions = isOptions(options);
  if (usingOptions) {
    const result = options[bp] ?? getSmallestFromOptions(bp, options);

    if (isValue(result)) {
      return result as T;
    }
  }

  if (!usingOptions && isValue(options)) {
    return options as T;
  }

  if (defaults.options) {
    const result = getSmallestFromOptions(bp, defaults.options);

    if (isValue(result)) {
      return result as T;
    }
  }

  return defaults.default;
};

export const DEFAULT_COLS: BreakpointDefaults<number> = {
  options: { sm: 1, md: 3 },
  default: 3
};

export const DEFAULT_CLASS_NAME: BreakpointDefaults<string> = {
  default: ''
};

export const DEFAULT_ROWS_PER_CELL: BreakpointDefaults<number> = {
  default: 1
};
export const DEFAULT_OVERSCAN_CELLS: BreakpointDefaults<number> = {
  default: 1
};

export const DEFAULT_SIZE: BreakpointDefaults<number> = {
  default: 750
};

export const ResponsiveVirtualGrid = <T extends HTMLElement>({
  className,
  children,
  count,
  cols,
  classNames,
  rowsPerCell,
  overscanCells,
  defaultSize
}: IResponsiveVirtualGridProps<T>) => {
  const { active: bp } = useScreenBreakpoint();

  const colsValue = getParamValue(bp, cols, DEFAULT_COLS);
  const classNameValue = getParamValue(bp, classNames, DEFAULT_CLASS_NAME);
  const rowsPerCellValue = getParamValue(
    bp,
    rowsPerCell,
    DEFAULT_ROWS_PER_CELL
  );
  const overscanCellsValue = getParamValue(
    bp,
    overscanCells,
    DEFAULT_OVERSCAN_CELLS
  );
  const defaultSizeValue = getParamValue(bp, defaultSize, DEFAULT_SIZE);

  const key = `grid-${colsValue}-${rowsPerCellValue}-${overscanCellsValue}-${defaultSizeValue}`;

  return (
    <div data-breakpoint={bp} className={cn(className, 'w-full')}>
      <VirtualGrid
        key={key}
        count={count}
        cols={colsValue}
        className={classNameValue}
        rowsPerCell={rowsPerCellValue}
        defaultSize={defaultSizeValue}
        overscanCells={overscanCellsValue}
      >
        {children}
      </VirtualGrid>
    </div>
  );
};
