import type { Translations } from '@gemini/shared/services/content/translations';
import type { ProductType } from '@gemini/shared/services/products/catalog';
import {
  sortProductsByDefaultPriceAsc,
  sortProductsByDefaultPriceDesc,
  sortProductsTopRated
} from '@gemini/shared/services/products/product-sort';
import {
  createGetDefaultIndexFn,
  createProductSortFilter,
  getProductSortInitialFilterData,
  ISortFilter
} from '@gemini/shared/ui/organisms/product-filter-sort';

export type IDisplayableSortFilter = ISortFilter & { label: string };

export interface IOptionsLabels {
  priceAscLabel?: string;
  priceDescLabel?: string;
  topRatedLabel?: string;
  translations?: Translations;
}

export interface ICreateSdProductFilterSortConfig extends IOptionsLabels {
  products: ProductType[];
  options?: IDisplayableSortFilter[];
}

export type ICreateSdSortOptionsConfig = IOptionsLabels;

export const SORT_PRICE_ASC = 'sort_price_asc';
export const SORT_PRICE_DESC = 'sort_price_desc';
export const SORT_TOP_RATED = 'sort_top_rated';

export function createSdSortOptions({
  priceAscLabel,
  priceDescLabel,
  topRatedLabel,
  translations
}: ICreateSdSortOptionsConfig): IDisplayableSortFilter[] {
  const optionPriceAscLabel =
    priceAscLabel ??
    translations?.elc_product?.sort_price_low_to_high ??
    'Price (Low to High)';
  const optionPriceDescLabel =
    priceDescLabel ??
    translations?.elc_product?.sort_price_high_to_low ??
    'Price (High to Low)';
  const optionTopRatedLabel =
    topRatedLabel ?? translations?.elc_product?.sort_top_rated ?? 'Top Rated';

  return [
    {
      label: optionTopRatedLabel,
      name: SORT_TOP_RATED,
      sort: sortProductsTopRated
    },
    {
      label: optionPriceDescLabel,
      name: SORT_PRICE_DESC,
      sort: sortProductsByDefaultPriceDesc
    },
    {
      label: optionPriceAscLabel,
      name: SORT_PRICE_ASC,
      sort: sortProductsByDefaultPriceAsc
    }
  ];
}

export const FILTER_SORT_NAME = 'sort';

export function createSdProductSortFilter({
  products,
  options,
  ...optionsConfig
}: ICreateSdProductFilterSortConfig) {
  const getDefaultIndex = createGetDefaultIndexFn(products);

  const sortOptions = options || createSdSortOptions({ ...optionsConfig });

  const filter = createProductSortFilter({
    sortFilters: sortOptions,
    getDefaultIndex
  });

  return {
    filter,
    getInitialFilterData: getProductSortInitialFilterData,
    sortOptions
  };
}
