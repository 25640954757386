import cn from 'classnames';
import { FC, useCallback, useRef, useState } from 'react';
import { Icon } from '@gemini/shared/ui/molecules/icon';
import { useOnClickOutside } from '@gemini/shared/ui/utils/hooks';
import { IMultiSelectProps } from './IMultiSelectProps';
import { useMultiSelect } from './useMultiSelect';

export const MultiSelectDropdown: FC<IMultiSelectProps> = ({
  className,
  label,
  options,
  value,
  onChange
}) => {
  const { selectedCount, selectedSet, onOptionClick } = useMultiSelect({
    value,
    onChange
  });

  const ref = useRef<HTMLDivElement>(null);
  const [isDropdownVisible, setDropdownVisible] = useState(false);

  const onClickOutside = useCallback(() => {
    setDropdownVisible(false);
  }, []);

  useOnClickOutside(ref, onClickOutside);

  const onLabelClick = useCallback(() => {
    setDropdownVisible((visible) => !visible);
  }, []);

  const renderText = (numCount: number) => (
    <>
      {label}
      {numCount > 0 && ` (${numCount})`}
    </>
  );

  return (
    <div
      className={cn(className, 'font-akzidenzgrotesk relative pr-[28px]')}
      ref={ref}
    >
      <div
        onClick={onLabelClick}
        role="button"
        className={cn(
          'cursor-pointer flex text-[12px] text-navy uppercase py-[10px] tracking-[1.8px] select-none',
          {
            'font-bold': isDropdownVisible
          }
        )}
      >
        <div className="relative">
          <div>{renderText(selectedCount)}</div>
          <div className="opacity-0 font-bold h-[0px] overflow-y-hidden">
            {renderText(9)}
          </div>
        </div>
        <div className="pr-[20px] relative">
          <div
            className={cn(
              '-translate-y-[50%] right-0 top-[50%] absolute transition-all duration-300',
              {
                'rotate-180': isDropdownVisible
              }
            )}
          >
            <Icon
              width={14}
              height={16}
              fill="#000000"
              name="ArrowDownFooter"
            />
          </div>
        </div>
      </div>
      {isDropdownVisible && (
        <div className="absolute left-0 -mt-[10px] z-10 bg-white border-slate-400/10 text-[14px] p-[15px] leading-tight">
          {options.map((o, idx) => (
            <div
              key={`${o.value}-${idx}`}
              className={cn(
                'py-[5px] whitespace-nowrap cursor-default flex items-center select-none',
                {
                  'opacity-40': o.disabled
                }
              )}
              onClick={() => onOptionClick(o)}
            >
              <div
                className={cn('flex-0 pr-[12px]', {
                  'cursor-pointer': !o.disabled,
                  'cursor-not-allowed': o.disabled
                })}
              >
                <Icon
                  width={12}
                  height={12}
                  fill="#000000"
                  name={
                    selectedSet.has(o.value) ? 'CheckboxChecked' : 'Checkbox'
                  }
                />
              </div>
              <div>{o.label}</div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
