import { FC } from 'react';
import { ISelectProps } from '@gemini/shared/ui/organisms/sd-product-filter';
import { useBreakpoint } from '@gemini/shared/ui/utils/hooks';
import { MultiSelectAccordion } from './MultiSelectAccordion';
import { MultiSelectDropdown } from './MultiSelectDropdown';

export const FilterSelect: FC<ISelectProps> = (props) => {
  const { isMobile } = useBreakpoint('block md:hidden');

  return isMobile ? (
    <MultiSelectAccordion {...props} />
  ) : (
    <MultiSelectDropdown {...props} />
  );
};
