import cn from 'classnames';
import { FC, PropsWithChildren, useContext, useEffect, useState } from 'react';
import { AccordionBase } from '@gemini/shared/ui/molecules/accordion';
import { Icon } from '@gemini/shared/ui/molecules/icon';
import { IMultiSelectProps } from './IMultiSelectProps';
import styles from './MultiSelectAccordion.module.scss';
import { MultiSelectDropdownStateContext } from './MultiSelectDropdownStateContext';
import { useMultiSelect } from './useMultiSelect';

const OpenCloseIconWrapper: FC<PropsWithChildren<unknown>> = ({ children }) => (
  <div className="mt-[5px]">{children}</div>
);

const ClosedIcon = () => (
  <OpenCloseIconWrapper>
    <Icon name="Plus" width={11} height={11} asImg />
  </OpenCloseIconWrapper>
);

const OpenedIcon = () => (
  <OpenCloseIconWrapper>
    <Icon name="Minus" width={11} height={11} asImg />
  </OpenCloseIconWrapper>
);

export const MultiSelectAccordion: FC<IMultiSelectProps> = ({
  options,
  value,
  onChange,
  label,
  className,
  name
}) => {
  const { selectedSet, selectedCount, onOptionClick } = useMultiSelect({
    value,
    onChange
  });

  const dropdownState = useContext(MultiSelectDropdownStateContext);

  const [isOpen, setOpen] = useState(() =>
    name ? dropdownState.accordionInitiallyExpanded.get(name) || false : false
  );

  useEffect(() => {
    if (name) {
      dropdownState.accordionInitiallyExpanded.set(name, isOpen);
    }
  }, [isOpen, name, dropdownState]);

  return (
    <AccordionBase
      className={className}
      isOpen={isOpen}
      onChange={setOpen}
      closedIcon={ClosedIcon}
      openedIcon={OpenedIcon}
      title={
        <div className="flex">
          <div>{label}</div>
          {selectedCount > 0 && (
            <div className={cn('pl-[10px] multi-select', styles.count)}>
              {selectedCount}
            </div>
          )}
        </div>
      }
      titleClassName={cn(
        'py-[15px] text-[14px] font-akzidenzgrotesk uppercase tracking-[.15em] cursor-pointer leading-[14px]'
      )}
    >
      <div className="py-[10px]">
        {options.map((o, idx) => (
          <div
            key={`${o.value}-${idx}`}
            className={cn(
              'py-[5px] mb-[5px] font-akzidenzgrotesk text-[14px] tracking-[0.25px] flex whitespace-nowrap items-center cursor-default leading-[1] select-none',
              {
                'opacity-40': o.disabled
              }
            )}
            onClick={() => onOptionClick(o)}
          >
            <div
              className={cn('mr-[17px] w-[20px] h-[20px] pl-[6px] pt-[4px]', {
                'cursor-pointer': !o.disabled
              })}
            >
              <Icon
                width={12}
                height={12}
                fill="#000000"
                name={selectedSet.has(o.value) ? 'CheckboxChecked' : 'Checkbox'}
              />
            </div>
            <div>{o.label}</div>
          </div>
        ))}
      </div>
    </AccordionBase>
  );
};
