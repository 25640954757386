import { FC, useState } from 'react';
import { useBreakpoint } from '@gemini/shared/ui/utils/hooks';
import { IProductGridFiltersProps } from './IProductGridFiltersProps';
import {
  IMultiSelectDropdownState,
  MultiSelectDropdownStateContext
} from './MultiSelectDropdownStateContext';
import { ProductGridFiltersDesktop } from './ProductGridFiltersDesktop';
import { ProductGridFiltersMobile } from './ProductGridFiltersMobile';

export const ProductGridFilters: FC<IProductGridFiltersProps> = (props) => {
  const { isMobile, isDesktop } = useBreakpoint('block md:hidden');
  const [multiselectDropdownState] = useState<IMultiSelectDropdownState>(
    () => ({
      accordionInitiallyExpanded: new Map()
    })
  );

  return (
    <>
      <MultiSelectDropdownStateContext.Provider
        value={multiselectDropdownState}
      >
        {isMobile && (
          <div className="block md:hidden">
            <ProductGridFiltersMobile {...props} />
          </div>
        )}
      </MultiSelectDropdownStateContext.Provider>
      {isDesktop && (
        <div className="hidden md:block">
          <ProductGridFiltersDesktop {...props} />
        </div>
      )}
    </>
  );
};
