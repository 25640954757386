import { createContext } from 'react';

export interface IMultiSelectDropdownState {
  accordionInitiallyExpanded: Map<string, boolean>;
}

export const MultiSelectDropdownStateContext =
  createContext<IMultiSelectDropdownState>({
    accordionInitiallyExpanded: new Map()
  });
