import camelCase from 'lodash.camelcase';
import {
  FilterQueryKeyType,
  FilterQueryLevel,
  IFilterMapItem
} from '@gemini/shared/services/products/next-sd-prodcat';

export interface ICreateFilterQueryConfig {
  level: FilterQueryLevel;
  map?: IFilterMapItem[];
}

export interface IFilterableSku {
  skuId: string;

  [name: string]: unknown;
}

export interface IFilterableProduct {
  productId: string;
  skus: IFilterableSku[];

  [name: string]: unknown;
}

function isFilterableProduct(item: unknown): item is IFilterableProduct {
  return !!(
    item &&
    (item as { productId?: string }).productId &&
    (item as { skus?: unknown[] }).skus &&
    Array.isArray((item as { skus: unknown[] }).skus)
  );
}

function isFilterableSku(obj: unknown): obj is IFilterableSku {
  return !!(obj && (obj as { skuId: string }).skuId);
}

function extractFromEntity(
  entityData: unknown,
  filterMap: IFilterMapItem[],
  index: number,
  acc: Set<string>
) {
  const accessor = filterMap[index];

  if (!accessor) {
    return;
  }
  const addToValues = index === filterMap.length - 1;

  if (accessor.key_type === FilterQueryKeyType.MACHINE_NAME && entityData) {
    const val = (entityData as Record<string, unknown>)[accessor.key_name];
    if (addToValues) {
      acc.add(val as string);
    } else {
      extractFromEntity(val, filterMap, index + 1, acc);
    }
  } else if (
    accessor.key_type === FilterQueryKeyType.ARRAY_OF_OBJECTS &&
    entityData
  ) {
    const arr = (entityData as Record<string, unknown>)[accessor.key_name];
    if (arr && Array.isArray(arr) && filterMap[index + 1]) {
      if (addToValues) {
        arr.forEach((e) => acc.add(e));
      } else {
        arr.forEach((e) => extractFromEntity(e, filterMap, index + 1, acc));
      }
    }
  } else if (accessor.key_type === FilterQueryKeyType.OBJECT && entityData) {
    const obj = (entityData as Record<string, unknown>)[accessor.key_name];
    if (addToValues) {
      acc.add(obj as string);
    } else if (obj) {
      extractFromEntity(obj, filterMap, index + 1, acc);
    }
  }
}

export type IFilterQueryAccumulator = (item: unknown, acc: Set<string>) => void;

export function createFilterQuery({
  level,
  map = []
}: ICreateFilterQueryConfig): IFilterQueryAccumulator {
  const saneMap = map.map<IFilterMapItem>((i) => ({
    ...i,
    // convert to entity casing
    key_name: camelCase(i.key_name)
  }));

  return (item: unknown, acc: Set<string>) => {
    if (level === FilterQueryLevel.PRODUCT) {
      extractFromEntity(item, saneMap, 0, acc);
    } else if (level === FilterQueryLevel.SKU) {
      if (isFilterableProduct(item)) {
        item.skus?.forEach((sku) => extractFromEntity(sku, saneMap, 0, acc));
      } else if (isFilterableSku(item)) {
        extractFromEntity(item, saneMap, 0, acc);
      }
    }

    return acc;
  };
}
