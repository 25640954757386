import { ChangeEventHandler, FC, useCallback } from 'react';
import { ISelectProps } from './ISelectProps';

export const Select: FC<ISelectProps> = ({
  label,
  options,
  value,
  onChange
}) => {
  const onSelectChange = useCallback<ChangeEventHandler<HTMLSelectElement>>(
    (e) => {
      const v = Array.from(e.target.selectedOptions).map((o) => o.value);
      onChange?.(v);
    },
    [onChange]
  );

  return (
    <label>
      {label}
      <select value={value} multiple onChange={onSelectChange}>
        {options?.map((o) => (
          <option key={o.value} value={o.value} disabled={o.disabled}>
            {o.label}
          </option>
        ))}
      </select>
    </label>
  );
};
