import { useWindowVirtualizer, Virtualizer } from '@tanstack/react-virtual';
import cn from 'classnames';
import {
  CSSProperties,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import {
  DimensionsResize,
  useDebouncedCallback,
  useOnResize,
  useOnWindowScroll
} from '@gemini/shared/ui/utils/hooks';

export interface IRenderConfig<T extends HTMLElement> {
  firstCell: boolean;

  lastCell: boolean;

  /**
   * Item start in the collection
   */
  itemStart: number;

  /**
   * Item end in the collection, before to end extraction
   */
  itemEnd: number;

  /**
   * The cell key
   */
  key: string | number;

  cellIndex: number;

  /**
   * Cell size
   */
  size: number;

  /**
   * The offset for the cell
   */
  start: number;

  /**
   * Cell end
   */
  end: number;

  /**
   * Hint whether to animate or not the products
   */
  animate: boolean;

  /**
   * The styles to apply to the cell. Utility data, you can set whatever offset mechanism you want.
   * Recommending you use translateY() since it's GPU accelerated.
   */
  style: CSSProperties;

  measureElement(e: T | null): void;
}

export interface IVirtualGridProps<T extends HTMLElement> {
  count: number;

  cols?: number;

  rowsPerCell?: number;

  overscanCells?: number;

  defaultSize?: number;

  /**
   * Used to render a cell
   * @param config The cell render parameters
   */
  children: (config: IRenderConfig<T>) => ReactNode;

  className?: string;

  enableSmoothScroll?: boolean;
}

/**
 * Renders a virtual grid that uses the window as a
 * @param props
 * @constructor
 */
export const VirtualGrid = <T extends HTMLElement>(
  props: IVirtualGridProps<T>
) => {
  const {
    count,
    cols = 1,
    rowsPerCell = 3,
    overscanCells = 2,
    children,
    defaultSize = 650,
    className,
    enableSmoothScroll = true
  } = props;
  const containerRef = useRef<HTMLDivElement>(null);
  const [isServer, setIsServer] = useState(true);
  useEffect(() => {
    setIsServer(typeof window === 'undefined');
  }, []);

  const estimateSize = useCallback(() => defaultSize, [defaultSize]);

  const cellRows = Math.ceil(count / (cols * rowsPerCell));

  const [animate, setAnimate] = useState<boolean>(false);

  const restoreAnimation = useDebouncedCallback(
    () => {
      setAnimate(true);
    },
    700,
    []
  );

  useOnWindowScroll(
    useCallback(() => {
      if (animate) {
        setAnimate(false);
      }
      restoreAnimation();
    }, [animate])
  );

  const scrollToFn = useCallback(() => {
    /* noop */
  }, []);

  const virtualizer = useWindowVirtualizer<T>({
    count: cellRows,
    overscan: overscanCells,
    estimateSize,
    scrollToFn,
    enableSmoothScroll,
    observeElementOffset: (instance, cb) => {
      if (typeof window === 'undefined') {
        return;
      }

      const onScroll = () =>
        cb(-(containerRef.current?.getBoundingClientRect().top ?? 0));
      window.addEventListener('scroll', onScroll, {
        capture: false,
        passive: true
      });

      return () => {
        window.removeEventListener('scroll', onScroll);
      };
    }
  });

  const onResize = useDebouncedCallback(
    () => {
      virtualizer.measure();
    },
    300,
    [virtualizer]
  );

  useOnResize(containerRef, onResize, DimensionsResize.WIDTH);

  const virtualItems = virtualizer.getVirtualItems();

  return (
    <div
      ref={containerRef}
      className={cn('relative w-full', className)}
      style={{
        height: isServer
          ? `${cellRows * rowsPerCell * defaultSize}px`
          : `${virtualizer.getTotalSize()}px`
      }}
      data-range-start={virtualItems[0]?.index * cols * rowsPerCell ?? -1}
      data-range-end={
        virtualItems[virtualItems.length - 1]?.index * cols * rowsPerCell +
          cols * rowsPerCell -
          1 ?? -1
      }
    >
      {virtualItems.map((virtualItem, idx) => {
        const measureElement = (e: T | null) =>
          e ? virtualItem.measureElement(e) : undefined;
        const cellIndex = virtualItem.index;
        const firstCell = cellIndex === 0;
        const lastCell = idx === virtualItems.length - 1;

        const itemStart = cellIndex * cols * rowsPerCell;
        const itemEnd = Math.min(count, itemStart + cols * rowsPerCell);

        const key = virtualItem.key;
        const start = virtualItem.start;
        const end = virtualItem.end;
        const size = virtualItem.size;
        const style: CSSProperties = {
          transform: `translateY(${virtualItem.start}px)`
        };

        return children({
          cellIndex,
          itemStart,
          itemEnd,
          size,
          animate,
          end,
          key,
          start,
          style,
          measureElement,
          firstCell,
          lastCell
        });
      })}
    </div>
  );
};
