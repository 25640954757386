import { FC } from 'react';
import { isProd } from '@gemini/shared/services/utils/global';
import { FilterType } from './FilterType';
import { FILTER_WIDGET_MAPPING } from './filterWidgets';
import { useSDProductSubFilter } from './useSDProductSubFilter';

export interface ISDProductFilterProps {
  name: string;
}

const FilterErrorMessage: FC<{ message: string }> = ({ message }) =>
  isProd() ? null : <span className="px-1 text-error">{message}</span>;
export const SDProdcutFilter: FC<ISDProductFilterProps> = ({ name }) => {
  const subFilterInfo = useSDProductSubFilter(name);
  if (!subFilterInfo) {
    return <FilterErrorMessage message={`No filter for ${name}`} />;
  }
  const sdFilter = subFilterInfo.filter;

  const Component =
    FILTER_WIDGET_MAPPING[sdFilter.filterSets.type as unknown as FilterType];

  if (!Component) {
    return (
      <FilterErrorMessage
        message={`No widget for ${name} - ${sdFilter.filterSets.type}`}
      />
    );
  }

  return <Component name={name} />;
};
