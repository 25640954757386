import type {
  ProductType,
  SkuType
} from '@gemini/shared/services/products/catalog';
import { ISortAcceptFn, ISortConfig, ISortFn } from './ISort';

export type IProductSortFn<T extends ProductType = ProductType> = ISortFn<T>;

export type IProductSortAcceptFn<T extends ProductType = ProductType> =
  ISortAcceptFn<T>;

export const sortByProductId = (a: ProductType, b: ProductType) => {
  return `${a.productId}`.localeCompare(`${b.productId}`);
};

export const sortByOrder = (
  a: ProductType,
  b: ProductType,
  config: ISortConfig<ProductType>
) => {
  const i1 = config.getDefaultIndex(a);
  const i2 = config.getDefaultIndex(b);

  if (i1 !== -1 && i2 !== -1) {
    return i1 - i2;
  }

  return 0;
};

export const sortProductsWithFallback = (
  config: ISortConfig<ProductType>,
  sortFn: (a: ProductType, b: ProductType) => number
): ProductType[] => {
  const sort = (a: ProductType, b: ProductType) =>
    sortFn(a, b) || sortByOrder(a, b, config) || sortByProductId(a, b);

  return config.items.slice().sort(sort);
};

const BEST_SELLING_PRIORITY: Record<string, number> = {
  'best seller': 2,
  'best sellers': 2,
  bestseller: 2,
  bestsellers: 2,
  new: 1,
  'new arrival': 1,
  'new arrivals': 1
};

const NEW_PRIORITY: Record<string, number> = {
  'best seller': 1,
  'best sellers': 1,
  bestseller: 1,
  bestsellers: 1,
  new: 2,
  'new arrival': 2,
  'new arrivals': 2
};

enum InventoryStatus {
  Active = 'Active',
  TempOutOfStock = 'Temporarily out of stock',
  ComingSoon = 'Coming Soon',
  SoldOut = 'Sold Out',
  FreePromo = 'Free Promo',
  DoNotDisplay = 'Do Not Display',
  Inactive = 'Inactive',
  PreOrder = 'Pre Order',
  OnBag = 'On Bag'
}

const NONDISPLAYABLE_STATUSES = [
  InventoryStatus.DoNotDisplay,
  InventoryStatus.Inactive,
  InventoryStatus.FreePromo,
  InventoryStatus.SoldOut
];

const sortAsc = (a: ProductType, b: ProductType) =>
  (a.skus[0].prices[0].includeTax.price || 0) -
  (b.skus[0].prices[0].includeTax.price || 0);

const sortDesc = (a: ProductType, b: ProductType) =>
  (b.skus[0].prices[0].includeTax.price || 0) -
  (a.skus[0].prices[0].includeTax.price || 0);

const sortHighestRating = (a: ProductType, b: ProductType) =>
  (b.averageRating || 0) - (a.averageRating || 0);

const sortByBadgePriority =
  (priority: Record<string, number>) => (a: ProductType, b: ProductType) =>
    (priority[b.badge?.toLowerCase() ?? ''] ?? 0) -
    (priority[a.badge?.toLowerCase() ?? ''] ?? 0);

const sortNew = sortByBadgePriority(NEW_PRIORITY);
const sortBestSelling = sortByBadgePriority(BEST_SELLING_PRIORITY);

const productHasBestSellerFlag = (item: ProductType) =>
  item.badge &&
  ['best seller', 'best sellers', 'bestseller', 'bestsellers'].includes(
    item.badge.toLowerCase()
  );

const productHasNewFlag = (item: ProductType) =>
  item.badge &&
  ['new', 'new arrival', 'new arrivals'].includes(item.badge.toLowerCase());

export const isSkuDisplayable = (sku: SkuType) =>
  !NONDISPLAYABLE_STATUSES.includes(sku.inventoryStatus as InventoryStatus);

export const isActiveDefaultSku = (sku: SkuType) =>
  isSkuDisplayable(sku) ||
  sku.inventoryStatus === InventoryStatus.TempOutOfStock;

const getDefaultSku = (p: ProductType) =>
  p.skus.find((s) => s.prices.length > 0);

const getAvailableSkus = (p: ProductType) =>
  p.skus.filter(isActiveDefaultSku).filter((s) => s.prices.length > 0);

const getDefaultPrice = (p: ProductType): number => {
  const availableSkus = getAvailableSkus(p);

  const sku = availableSkus[0] || getDefaultSku(p) || p.skus[0];

  const price = sku.prices[0];

  if (!price) {
    return 0;
  }

  const taxPrice = price.includeTax.isDiscounted
    ? price.includeTax.price
    : price.includeTax.originalPrice;

  return taxPrice ?? price.price ?? 0;
};

const sortByDefaultPriceDesc = (p1: ProductType, p2: ProductType): number =>
  getDefaultPrice(p2) - getDefaultPrice(p1);
const sortByDefaultPriceAsc = (p1: ProductType, p2: ProductType): number =>
  getDefaultPrice(p1) - getDefaultPrice(p2);
const sortTopRated = (p1: ProductType, p2: ProductType): number => {
  const r1 = p1.averageRating;
  const r2 = p2.averageRating;

  return r1 && r2 ? r2 - r1 : r1 && !r2 ? -1 : !r1 && r2 ? 1 : 0;
};

export const acceptProductsBestSelling: IProductSortAcceptFn = ({ items }) =>
  items.some(productHasBestSellerFlag);
export const acceptProductsNew: IProductSortAcceptFn = ({ items }) =>
  items.some(productHasNewFlag);

export const sortProductsBestSelling: IProductSortFn = (config) =>
  sortProductsWithFallback(config, sortBestSelling);
export const sortProductsHighestRating: IProductSortFn = (config) =>
  sortProductsWithFallback(config, sortHighestRating);
export const sortProductsNew: IProductSortFn = (config) =>
  sortProductsWithFallback(config, sortNew);
export const sortProductsAsc: IProductSortFn = (config) =>
  sortProductsWithFallback(config, sortAsc);
export const sortProductsDesc: IProductSortFn = (config) =>
  sortProductsWithFallback(config, sortDesc);
export const sortProductsByDefaultPriceAsc: IProductSortFn = (config) =>
  sortProductsWithFallback(config, sortAsc);
export const sortProductsByDefaultPriceDesc: IProductSortFn = (config) =>
  sortProductsWithFallback(config, sortDesc);
export const sortProductsTopRated: IProductSortFn = (config) =>
  sortProductsWithFallback(config, sortTopRated);
