import { FC, useCallback, useDeferredValue, useMemo, useState } from 'react';
import { useTranslations } from '@gemini/shared/services/content/translations';
import { Icon } from '@gemini/shared/ui/molecules/icon';
import { ProductFilterSort } from '@gemini/shared/ui/organisms/sd-product-grid';
import { IProductGridFiltersProps } from './IProductGridFiltersProps';
import { ProductGridFiltersModal } from './ProductGridFiltersModal';
import { SortDropdown } from './SortDropdown';

export const ProductGridFiltersMobile: FC<IProductGridFiltersProps> = (
  props
) => {
  const {
    filtersTitle,
    filterBy,
    filterNames,
    showFilters,
    sortName,
    showSort,
    count,
    showClearAll,
    onClearAll
  } = props;

  if (!(showFilters || showSort)) {
    return null;
  }
  const [isModalVisible, setModalVisible] = useState(false);

  const translations = useTranslations();

  const resultsLabel = translations.elc_product.results;

  const toggleFiltersModal = useCallback(() => {
    setModalVisible((v) => !v);
  }, []);

  const onModalClose = useCallback(() => {
    setModalVisible(false);
  }, []);

  const resultsFormattedLabel = useMemo(() => {
    return `${count} ${resultsLabel}`;
  }, [count]);

  const deferredResultsFormattedLabel = useDeferredValue(
    showClearAll ? resultsFormattedLabel : ''
  );

  return (
    <div className="flex">
      {showFilters && (
        <div className="flex items-center">
          <div
            className="flex uppercase font-akzidenzgrotesk text-[12px] tracking-[1px] pl-[20px] py-[10px]"
            role="button"
            onClick={toggleFiltersModal}
          >
            <div>{filtersTitle}</div>
            <div className="pl-[6px]">
              <Icon
                width={14}
                height={16}
                fill="#000000"
                name="ArrowDownFooter"
              />
            </div>
          </div>
          {showClearAll && (
            <div className="text-gray-500 tracking-[0.75px] font-akzidenzgrotesk text-[12px] pl-[8px]">
              {deferredResultsFormattedLabel}
            </div>
          )}
        </div>
      )}
      {showSort && (
        <div className="ml-auto">
          <ProductFilterSort component={SortDropdown} name={sortName} />
        </div>
      )}
      {isModalVisible && (
        <ProductGridFiltersModal
          {...props}
          isOpen={isModalVisible}
          onClose={onModalClose}
        />
      )}
    </div>
  );
};
