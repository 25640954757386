import { useRouter } from 'next/router';
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import type { IFilterSets } from '@gemini/shared/services/products/next-sd-prodcat';
import { FILTER_DELIMITTER, FilterService, readParams } from './FilterService';
import { IFilter } from './IFilter';

export const acceptFilterSet = (fs: any) =>
  !!(
    fs.query &&
    fs.query.length &&
    typeof fs.query.every === 'function' &&
    fs.query.every((q: any) => !!(q.map && q.level))
  );

export type IGetInitialFilterData = () => unknown;

export interface IFilterInfo {
  readonly name: string;
  readonly filter: IFilter;
  readonly getInitialFilterData: IGetInitialFilterData;
  readonly order: number;
  readonly category: string;
}

interface IFilterArray {
  readonly filters: IFilterInfo[];

  getByName(name: string): IFilterInfo | undefined;

  add(config: {
    name: string;
    filter: IFilter;
    getInitialFilterData: IGetInitialFilterData;
    order: number;
    category: string;
  }): () => void;

  clear(): void;
}

export const createFilterArray = (): IFilterArray => {
  let dirty = false;

  const nameMap = new Map<string, IFilterInfo>();
  const arr: IFilterInfo[] = [];

  const add: IFilterArray['add'] = ({
    name,
    filter,
    getInitialFilterData,
    order,
    category
  }) => {
    if (nameMap.has(name)) {
      throw new Error(`collection already has ${name}`);
    }

    const info: IFilterInfo = {
      name,
      getInitialFilterData,
      filter,
      order,
      category
    };

    nameMap.set(name, info);
    arr.push(info);

    return () => {
      nameMap.delete(name);
      const idx = arr.findIndex((i) => i.name === name);

      if (idx >= 0) {
        arr.splice(idx, 1);
      }
    };
  };

  const clear: IFilterArray['clear'] = () => {
    arr.splice(0, arr.length);
    nameMap.clear();
  };

  const getByName: IFilterArray['getByName'] = (name) => nameMap.get(name);

  return {
    add,
    clear,
    getByName,
    get filters() {
      if (dirty) {
        dirty = false;
        arr.sort((f1, f2) => {
          return f1.order - f2.order || f1.name.localeCompare(f2.name);
        });
      }

      return arr;
    }
  };
};

export interface IFilterSessionContextData<T = unknown> {
  items: T[];

  filteredItems: T[];

  filters: IFilterArray;

  filtersData: Record<string, unknown>;

  onFiltersDataChange(newData: Record<string, unknown>): void;

  getFiltersClearData(): Record<string, unknown>;
}

export const FilterSessionContext = createContext<IFilterSessionContextData>({
  items: [],
  filteredItems: [],
  filters: createFilterArray(),
  filtersData: {},
  onFiltersDataChange: () => {
    // NOOP
  },
  getFiltersClearData: () => ({})
});

export interface IFilterSessionProps<T> {
  filters: IFilterArray;

  items: T[];

  filteredItems: T[];

  onChange(items: T[]): void;

  filtersData: Record<string, unknown>;
  filterSets?: IFilterSets[];

  onFiltersDataChange(data: Record<string, unknown>): void;

  children?: ReactNode;
}

export const FilterSession = <T,>({
  filters,
  items,
  filteredItems,
  filtersData,
  filterSets,
  onFiltersDataChange,
  onChange,
  children
}: IFilterSessionProps<T>) => {
  const getFiltersClearData = useCallback(() => {
    return filters.filters.reduce((acc, f) => {
      acc[f.name] = f.getInitialFilterData();

      return acc;
    }, {} as Record<string, unknown>);
  }, [filters]);

  useEffect(() => {
    const filteredSets = filterSets?.filter(acceptFilterSet);
    const paramsFilter = {
      sd_product_filter: readParams(filteredSets),
      sort: filtersData.sort
    };
    onFiltersDataChange(paramsFilter);
  }, []);

  useEffect(() => {
    let newItems = items.slice();
    const fData = {
      ...filtersData
    };

    for (const filter of filters.filters) {
      if (!fData[filter.name]) {
        fData[filter.name] = filter.getInitialFilterData();
      }
    }

    for (const filter of filters.filters) {
      newItems = filter.filter({
        name: filter.name,
        items: newItems,
        data: fData[filter.name]
      }) as T[];
    }
    onChange(newItems);

    const sd_product_filter = filtersData?.sd_product_filter as any;
    const filterData = sd_product_filter?.filterData;

    if (filterData) {
      FilterService(filterData, filterSets);
    }
  }, [
    filters.filters.map((f) => f.name).join(FILTER_DELIMITTER),
    filtersData,
    items
  ]);

  return (
    <FilterSessionContext.Provider
      value={{
        filtersData,
        onFiltersDataChange,
        filters,
        items,
        filteredItems,
        getFiltersClearData
      }}
    >
      {children}
    </FilterSessionContext.Provider>
  );
};

export const useCategoryFiltersData = (
  category: string
): Record<string, unknown> => {
  const { filtersData, filters } = useContext(FilterSessionContext);
  const categoryFilters = useMemo(
    () => filters.filters.filter((f) => f.category === category),
    [filters.filters.map((f) => f.name).join(FILTER_DELIMITTER), category]
  );

  return useMemo(
    () =>
      categoryFilters.reduce((acc, f) => {
        acc[f.name] = filtersData[f.name];

        return acc;
      }, {} as Record<string, unknown>),
    [categoryFilters, ...categoryFilters.map((f) => filtersData[f.name])]
  );
};

const updateFiltersData = (
  data: Record<string, unknown>,
  filters: IFilterArray
) => {
  filters.filters.forEach(({ getInitialFilterData, name }) => {
    data[name] = getInitialFilterData();
  });
};

export const useFilterSession = <T,>(filters: IFilterArray, items: T[]) => {
  const [filtersData, setFiltersData] = useState<Record<string, unknown>>(
    () => {
      const data: Record<string, unknown> = {};

      updateFiltersData(data, filters);

      return data;
    }
  );

  const [filteredItems, setFilteredItems] = useState(() => items.slice());

  const onFiltersDataChange = useCallback(
    (newData: Record<string, unknown>) => {
      setFiltersData((oldData) => ({ ...oldData, ...newData }));
    },
    []
  );

  const onChange = (newFilteredItems: T[]) => {
    setFilteredItems(newFilteredItems);
  };

  const router = useRouter();
  useEffect(() => {
    updateFiltersData(filtersData, filters);
  }, [filters, router.asPath]);

  return {
    items,
    onChange,
    filtersData,
    onFiltersDataChange,
    filteredItems
  };
};

export const useFilter = <T, D>(name: string) => {
  const { items, filteredItems, filters, filtersData, onFiltersDataChange } =
    useContext(FilterSessionContext) as IFilterSessionContextData<T>;

  const filter = filters.getByName(name);

  const onDataChange = useCallback(
    (data: unknown) => {
      onFiltersDataChange({
        ...filtersData,
        [name]: data
      });
    },
    [name, filtersData]
  );

  return {
    items,
    filteredItems,
    filter,
    filterData: (filtersData[name] ?? filter?.getInitialFilterData()) as D,
    onDataChange
  };
};
