import cn from 'classnames';
import React, {
  ChangeEvent,
  FC,
  useCallback,
  useMemo,
  useRef,
  useState
} from 'react';
import { Icon } from '@gemini/shared/ui/molecules/icon';
import {
  useBreakpoint,
  useOnClickOutside
} from '@gemini/shared/ui/utils/hooks';

export type DropdownPosition = 'left' | 'right';

export interface IOption {
  label: string;
  value: string;
  disabled?: boolean;
}

export interface ISelectProps {
  label?: string;
  className?: string;
  value: string;
  options: IOption[];
  placeholder?: string;

  onChange?(value: string): void;

  name?: string;
  selectClassName?: string;
  dropdownClassName?: string;

  dropdownPosition: DropdownPosition;
}

export const SelectDropdown: FC<ISelectProps> = ({
  className,
  label,
  options,
  value,
  onChange,
  placeholder,
  dropdownPosition = 'left',
  selectClassName,
  dropdownClassName
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isDropdownVisible, setDropdownVisible] = useState(false);

  const optionsMap = useMemo(
    () =>
      options.reduce((acc, o) => {
        acc.set(o.value, o);

        return acc;
      }, new Map<string, IOption>()),
    [options]
  );

  const onClickOutside = useCallback(() => {
    setDropdownVisible(false);
  }, []);

  useOnClickOutside(ref, onClickOutside);

  const onLabelClick = useCallback(() => {
    setDropdownVisible((visible) => !visible);
  }, []);

  const onOptionClick = useCallback(
    (o: IOption) => {
      if (o.value !== value) {
        onChange?.(o.value);
      }
      setDropdownVisible(false);
    },
    [value, onChange]
  );

  const selectedLabel = useMemo(() => {
    const optionLabel = optionsMap.get(value)?.label;

    return optionLabel ?? placeholder ?? '';
  }, [value, optionsMap, placeholder]);

  const { isMobile, isDesktop } = useBreakpoint('block lg:hidden');

  const onMobileChange = useCallback(
    (e: ChangeEvent) => {
      onChange?.((e.target as HTMLSelectElement).value);
    },
    [onChange]
  );

  return (
    <>
      {isDesktop && (
        <div
          className={cn(className, 'font-akzidenzgrotesk relative')}
          ref={ref}
        >
          <div
            onClick={onLabelClick}
            role="button"
            className={cn(
              'cursor-pointer flex text-[12px] text-navy uppercase py-[3px] tracking-[1.8px] select-none'
            )}
          >
            <div className="relative">
              <div>{selectedLabel}</div>
            </div>
            <div className="pr-[20px] relative">
              <div
                className={cn('-translate-y-[50%] right-0 top-[50%] absolute', {
                  'rotate-180': isDropdownVisible
                })}
              >
                <Icon
                  width={14}
                  height={16}
                  fill="#000000"
                  name="ArrowDownFooter"
                />
              </div>
            </div>
          </div>
          {isDropdownVisible && (
            <div
              className={cn(
                'absolute border border-gray-300 mt-[1px] z-10',
                dropdownClassName,
                {
                  'left-0': dropdownPosition === 'left',
                  'right-0': dropdownPosition === 'right'
                }
              )}
            >
              {options.map((o, idx) => (
                <div
                  key={`${o.value}-${idx}`}
                  className={cn(
                    'bg-white text-black hover:bg-[#C8DEF4] hover:text-black font-akzidenzgrotesk uppercase text-[12px] leading-[14px] p-[10px] text-left tracking-[0.75px]',
                    {
                      'opacity-40': o.disabled,
                      'cursor-pointer': !o.disabled
                    }
                  )}
                  onClick={() => onOptionClick(o)}
                >
                  <div>{o.label}</div>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
      {isMobile && (
        <div className="relative">
          <select
            className={cn(
              'appearance-none bg-white text-black font-akzidenzgrotesk uppercase text-[10px] tracking-wide pr-[35px] pl-[10px] h-[40px]',
              selectClassName
            )}
            value={value}
            onChange={onMobileChange}
          >
            {options.map((o) => (
              <option value={o.value} key={o.value}>
                {o.label}
              </option>
            ))}
          </select>
          <div className="pointer-events-none absolute right-[10px] top-[50%] -translate-y-[50%]">
            <Icon
              fill="#000000"
              asImg
              name="ArrowDownFooter"
              width="12"
              height="12"
              label="ArrowDown"
            />
          </div>
        </div>
      )}
    </>
  );
};
