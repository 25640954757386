import { ComponentType } from 'react';
import { AttributeFilterWidget } from './filters/attributes/AttributeFilterWidget';
import { FilterType } from './FilterType';
import { IFilterWidgetProps } from './IFilterWidgetProps';

export const FILTER_WIDGET_MAPPING: Record<
  FilterType,
  ComponentType<IFilterWidgetProps>
> = {
  [FilterType.ATTRIBUTE]: AttributeFilterWidget,
  [FilterType.TAG]: AttributeFilterWidget
};
