import { FilterQueryLogic } from '@gemini/shared/services/products/next-sd-prodcat';

export type IFilterLogic = (options: string[], values: Set<string>) => boolean;

const AndFilterLogic: IFilterLogic = (options, values) =>
  options.every((option) => values.has(option));
const OrFilterLogic: IFilterLogic = (options, values) =>
  options.some((option) => values.has(option));

export function createFilterLogic(logic: FilterQueryLogic): IFilterLogic {
  const mapping: Record<FilterQueryLogic, IFilterLogic> = {
    [FilterQueryLogic.AND]: AndFilterLogic,
    [FilterQueryLogic.OR]: OrFilterLogic
  };

  return mapping[logic] || OrFilterLogic;
}
