import { ProductType } from '@gemini/shared/services/products/catalog';
import {
  FilterQueryLogic,
  IFilterData,
  IFiltersConfig
} from '@gemini/shared/services/products/next-sd-prodcat';
import type {
  IFilter,
  IGetInitialFilterData
} from '@gemini/shared/ui/organisms/filter';
import { createFilter } from './createFilter';
import { SD_PRODUCT_FILTER } from './filters/constants';
import {
  ISDProductFilter,
  ISDProductSingleFilterConfig
} from './IFilterSetFilter';
import {
  ISDProductFilterAggregator,
  ISDProductFilterAggregatorData,
  ISDProductFilterAggregatorFn,
  ISDProductFilterAggregatorInfo
} from './ISDProductFilterAggregator';

export interface ICreateSDProductFilterConfig {
  filters?: IFiltersConfig;
  filterData?: IFilterData;
}

export interface IFilterInfo {
  name: string;
}

export interface IGenerateSdProductFilterResult {
  name: string;
  filter: IFilter;
  getInitialFilterData: IGetInitialFilterData;
  names: string[];
}

interface ISubFilterInfo {
  name: string;
  filter: ISDProductFilter<unknown>;
  getInitialFilterData: IGetInitialFilterData;
}

export function createSDProductFilter({
  filters,
  filterData
}: ICreateSDProductFilterConfig): IGenerateSdProductFilterResult {
  const subFilterInfos: ISubFilterInfo[] = [];
  const subFilterInfoMap = new Map<string, ISubFilterInfo>();

  filterData?.filterSets?.forEach((fs) => {
    const createResult = createFilter(fs.type, fs);

    if (createResult) {
      const {
        filter: subFilter,
        getInitialFilterData: filterGetInitialFilterData
      } = createResult;
      const subFilterInfo: ISubFilterInfo = {
        filter: subFilter as ISDProductFilter<unknown>,
        getInitialFilterData: filterGetInitialFilterData,
        name: fs.key
      };

      subFilterInfos.push(subFilterInfo);
      subFilterInfoMap.set(fs.key, subFilterInfo);
    }
  });

  const andFilters = subFilterInfos.filter(
    (i) => i.filter.filterSets.logic === FilterQueryLogic.AND
  );
  const orFilters = subFilterInfos.filter(
    (i) => i.filter.filterSets.logic === FilterQueryLogic.OR
  );

  const filterAggregator: ISDProductFilterAggregatorFn = (config) => {
    const filtersConfig = subFilterInfos.reduce((acc, i) => {
      acc[i.name] = {
        data: config.data.filterData[i.name],
        items: config.items,
        item: null as unknown as ProductType
      };

      return acc;
    }, {} as Record<string, ISDProductSingleFilterConfig<unknown>>);

    return config.items.filter((i) => {
      const applyFilter = (info: ISubFilterInfo) => {
        const data = filtersConfig[info.name];
        data.item = i;

        return info.filter(data);
      };

      return (
        (!andFilters.length || andFilters.every(applyFilter)) &&
        (!orFilters.length || orFilters.some(applyFilter))
      );
    });
  };
  (filterAggregator as unknown as ISDProductFilterAggregatorInfo).getSubFilter =
    (name: string) => {
      return subFilterInfoMap.get(name)?.filter ?? null;
    };

  const getInitialFilterData: IGetInitialFilterData = () => {
    const fData = subFilterInfos.reduce((acc, info) => {
      acc[info.name] = info.getInitialFilterData();

      return acc;
    }, {} as Record<string, unknown>);

    const initialData: ISDProductFilterAggregatorData = {
      filterData: fData
    };

    return initialData;
  };

  return {
    name: SD_PRODUCT_FILTER,
    filter: filterAggregator as unknown as IFilter,
    getInitialFilterData,
    names: subFilterInfos.map((i) => i.name)
  };
}
