type ExportedViews = {
  data: any;
} & { [key: string]: any };

type SDProps = {
  config: Record<string, any>;
} & { [key: string]: any };

export const SDWrapperHoc =
  (Component: React.ComponentType<ExportedViews>) =>
  ({ config, ...props }: SDProps) =>
    <Component data={{ config, contentBlocks: { data: {} } }} {...props} />;

export default SDWrapperHoc;
