import { useCallback } from 'react';
import { ProductType } from '@gemini/shared/services/products/catalog';
import { useFilter } from '@gemini/shared/ui/organisms/filter';
import { SD_PRODUCT_FILTER } from './filters/constants';
import { ISDProductFilter } from './IFilterSetFilter';
import {
  ISDProductFilterAggregator,
  ISDProductFilterAggregatorData
} from './ISDProductFilterAggregator';

export const useSDProductSubFilter = <D,>(name: string) => {
  const info = useFilter<ProductType, ISDProductFilterAggregatorData>(
    SD_PRODUCT_FILTER
  );

  const onDataChange = useCallback(
    (newData: D) => {
      const oldAggregatorData = info.filterData;
      const newAggregatorData: ISDProductFilterAggregatorData = {
        ...oldAggregatorData,
        filterData: {
          ...oldAggregatorData.filterData,
          [name]: newData
        }
      };
      info.onDataChange(newAggregatorData);
    },
    [info.onDataChange, name, info.filterData]
  );

  if (info.filter?.filter) {
    const subFilter = (
      info.filter.filter as unknown as ISDProductFilterAggregator
    ).getSubFilter<D>(name);
    const { items, filteredItems } = info;

    return {
      filter: subFilter as ISDProductFilter<D>,
      filterData: (info.filterData as ISDProductFilterAggregatorData)
        .filterData[name] as D,
      onDataChange,
      items,
      filteredItems
    };
  }

  return null;
};
