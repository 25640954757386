import type { IFilterSets } from '@gemini/shared/services/products/next-sd-prodcat';
import { createAttributeFilter } from './filters/attributes/attributeFilter';
import { FilterType } from './FilterType';
import {
  IFilterSetFilterFactory,
  IFilterSetFilterInfo
} from './IFilterSetFilter';

export function createFilter(
  type: string,
  filterSets: IFilterSets
): IFilterSetFilterInfo<unknown> | null {
  const factories: Record<FilterType, IFilterSetFilterFactory> = {
    [FilterType.ATTRIBUTE]: createAttributeFilter,
    [FilterType.TAG]: createAttributeFilter
  };

  return factories[type as unknown as FilterType]?.(filterSets) ?? null;
}
