import { useCallback, useMemo } from 'react';
import type { ProductType } from '@gemini/shared/services/products/catalog';
import { useFilter } from '@gemini/shared/ui/organisms/filter';
import type {
  IProductSortFilter,
  IProductSortFilterData
} from './productSortFilter';

export function useProductSortFilter(name: string) {
  const { filterData, onDataChange, items, filteredItems, filter } =
    useFilter(name);
  const productSortFilterData = filterData as IProductSortFilterData;

  const activeSortName = productSortFilterData.sortName;

  const onActiveSortNameChange = useCallback(
    (newSortname: string) => {
      const newFilterData: IProductSortFilterData = {
        sortName: newSortname
      };
      onDataChange(newFilterData);
    },
    [onDataChange]
  );

  const sortFilters =
    (filter?.filter as IProductSortFilter | undefined)?.sortFilters || [];

  const visibleFilters = useMemo<Set<string>>(() => {
    const set = new Set<string>();

    sortFilters.forEach((f) => {
      const accept = f.accept
        ? f.accept({ items: items as ProductType[] })
        : true;
      if (accept) {
        set.add(f.name);
      }
    });

    return set;
  }, [items, filteredItems, sortFilters]);

  return {
    sortFilters,
    activeSortName,
    onActiveSortNameChange,
    visibleFilters
  };
}
