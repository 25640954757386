import { ComponentType, createContext } from 'react';
import type { ILabelProps } from './widgets/ILabelProps';
import type { ISelectProps } from './widgets/ISelectProps';
import { Label } from './widgets/Label';
import { Select } from './widgets/Select';

export interface IFiltersWidgets {
  Select: ComponentType<ISelectProps>;
  Label: ComponentType<ILabelProps>;
}

export const FiltersWidgetsContext = createContext<IFiltersWidgets>({
  Select,
  Label
});
