import camelCase from 'lodash.camelcase';
import type {
  ProductType,
  SkuType
} from '@gemini/shared/services/products/catalog';
import { FilterQueryLogic } from '@gemini/shared/services/products/next-sd-prodcat';
import { acceptFilterSet } from '@gemini/shared/ui/organisms/filter';
import type {
  IFilterSetFilterFactory,
  ISDProductFilter,
  ISDProductSingleFilterConfig
} from '../../IFilterSetFilter';
import { createFilterLogic } from './filterLogic';
import {
  createFilterQuery,
  IFilterableProduct,
  IFilterableSku
} from './filterQuery';

const getFilterableSku = (sku: SkuType): IFilterableSku => sku;

const getCamelCaseAttributes = (obj: Record<string, unknown>) =>
  Object.keys(obj).reduce<Record<string, unknown>>((acc, name) => {
    const camelCaseName = camelCase(name);

    if (name !== camelCaseName) {
      acc[camelCaseName] = obj[name];
    }

    return acc;
  }, {});

const getFilterableProduct = (item: ProductType): IFilterableProduct => {
  const camelCaseAttributes = getCamelCaseAttributes(item.attributes ?? {});

  return {
    // convert some attrribute values to camel case since filters are designed this way
    ...camelCaseAttributes,
    ...(item.attributes ?? {}),
    // dump attributes as being part of the object
    ...item,
    attributes: {
      ...camelCaseAttributes,
      ...(item.attributes ?? {})
    },
    skus: item.skus.map(getFilterableSku),
    // skin filters need a special structure so that filters can work
    skin: {
      type: item.attributes?.skinType,
      concern: item.attributes?.skinConcern,
      tone: item.attributes?.skinTone
    }
  };
};

const isProduct = (obj: unknown): obj is ProductType =>
  !!(obj as { productId?: string }).productId;

const isSku = (obj: unknown): obj is SkuType =>
  !!(obj as { skuId?: string }).skuId;

const getFilterableItem = (item: unknown) => {
  if (isProduct(item)) {
    return getFilterableProduct(item);
  }
  if (isSku(item)) {
    return getFilterableSku(item);
  }

  return item;
};

export interface IAttributeFilterData {
  options: string[];
}

export type IAttributeFilter = ISDProductFilter<IAttributeFilterData>;

export const createAttributeFilter: IFilterSetFilterFactory<
  IAttributeFilterData
> = (filterSet) => {
  if (acceptFilterSet(filterSet)) {
    const logic = createFilterLogic(FilterQueryLogic.OR);

    const filterQueries = filterSet.query.map((q) =>
      createFilterQuery({ level: q.level, map: q.map })
    );

    const filter = (({
      item,
      data
    }: ISDProductSingleFilterConfig<IAttributeFilterData>) => {
      const options = data?.options || [];

      if (options.length === 0) {
        return true;
      }

      const filterableItem = getFilterableItem(item);

      const values = new Set<string>();
      filterQueries.forEach((fq) => fq(filterableItem, values));

      return logic(options, values);
    }) as unknown as IAttributeFilter;

    filter.filterSets = filterSet;

    const getInitialFilterData = () => {
      return {
        options: []
      };
    };

    return {
      filter,
      getInitialFilterData
    };
  }

  return null;
};
