import cn from 'classnames';
import { FC, useDeferredValue, useMemo } from 'react';
import { useTranslations } from '@gemini/shared/services/content/translations';
import { SDProdcutFilter } from '@gemini/shared/ui/organisms/sd-product-filter';
import { ProductFilterSort } from '@gemini/shared/ui/organisms/sd-product-grid';
import { IProductGridFiltersProps } from './IProductGridFiltersProps';
import { SortDropdown } from './SortDropdown';

export const ProductGridFiltersDesktop: FC<IProductGridFiltersProps> = ({
  showClearAll,
  onClearAll,
  count,
  filterBy,
  showFilters,
  showSort,
  sortName,
  filterNames
}) => {
  const translations = useTranslations();

  const clearAllLabel =
    translations.elc_product.filter_clear_all ??
    translations.elc_general.clear_all ??
    'Clear All';
  const filterBylabel = translations.elc_product.filter_by_label;
  const resultsLabel = translations.elc_product.results;

  const resultsFormattedLabel = useMemo(() => {
    return `${count} ${resultsLabel}`;
  }, [count]);

  const deferredResultsFormattedLabel = useDeferredValue(
    showClearAll ? resultsFormattedLabel : ''
  );

  return (
    <>
      {(showSort || showFilters) && (
        <div
          className={cn('flex justify-between md:-mt-[2px] md:mr-0', {
            'md:mb-2': !showFilters
          })}
        >
          {showFilters && (
            <div className="flex">
              {filterBy && (
                <div className="pt-[10px] uppercase font-akzidenzgrotesk text-[12px] tracking-[1.25px] min-h-[50px]">
                  <span className="pr-30px">{filterBylabel}</span>
                  {showClearAll && (
                    <div className="text-gray-500 mt-[4px] tracking-[0.75px]">
                      {deferredResultsFormattedLabel}
                    </div>
                  )}
                </div>
              )}
              {filterNames.map((n) => (
                <div key={n}>
                  <SDProdcutFilter name={n} />
                </div>
              ))}
              {showClearAll && (
                <div onClick={onClearAll}>
                  <button className="appearance-none font-akzidenzgrotesk text-[12px] underline mt-[10px] text-gray-500 uppercase tracking-[0.15em]">
                    {clearAllLabel}
                  </button>
                </div>
              )}
            </div>
          )}
          {showSort && (
            <div className="ml-auto lg:py-[5px]">
              <ProductFilterSort component={SortDropdown} name={sortName} />
            </div>
          )}
        </div>
      )}
    </>
  );
};
