import {
  ITemplateFieldNodeReference,
  ITemplateFieldString
} from '@gemini/shared/services/content/next-mantlecms';
import { TemplateRenderer } from '@gemini/shared/ui/templates/template-renderer';

export interface IInlineContentItem {
  colspan: ITemplateFieldString;
  content: ITemplateFieldNodeReference;
  position: ITemplateFieldString;
}

interface IProductToutProps {
  tout: IInlineContentItem;
}

export function ProductTout(props: IProductToutProps) {
  const { tout } = props;

  if (!tout.content.data?.templates) {
    return null;
  }

  const item = tout.content.data?.templates[0];

  return (
    <TemplateRenderer
      component={item.component}
      data={item.data}
      metadata={item.metadata}
    />
  );
}
