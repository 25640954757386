import { ComponentType, FC, useCallback, useMemo } from 'react';
import { useProductSortFilter } from '@gemini/shared/ui/organisms/product-filter-sort';
import { FILTER_SORT_NAME, IDisplayableSortFilter } from './sort';

export interface IDropdownOption {
  label: string;
  value: string;
}

export interface IDropdownProps {
  value: string;

  onChange?(value: string): void;

  disabled?: boolean;

  options: IDropdownOption[];
}

const NO_FILTERS: IDisplayableSortFilter[] = [
  {
    sort: (config) => config.items.slice(),
    name: 'no_sort',
    label: 'No sort'
  }
];

export interface IProductFilterSortProps {
  name?: string;
  component: ComponentType<IDropdownProps>;
  disabled?: boolean;
}

export const ProductFilterSort: FC<IProductFilterSortProps> = ({
  name = FILTER_SORT_NAME,
  component: Dropdown,
  disabled
}) => {
  const {
    visibleFilters,
    sortFilters,
    activeSortName,
    onActiveSortNameChange
  } = useProductSortFilter(name);

  const options = useMemo(
    () =>
      sortFilters
        .filter((f) => visibleFilters.has(f.name))
        .map<IDropdownOption>((f) => ({
          value: f.name,
          label: (f as IDisplayableSortFilter).label
        })),
    [sortFilters, visibleFilters]
  );

  const onChange = useCallback(
    (newValue: string) => {
      onActiveSortNameChange(newValue);
    },
    [onActiveSortNameChange]
  );

  return (
    <>
      <Dropdown
        value={activeSortName ?? ''}
        options={options}
        disabled={disabled}
        onChange={onChange}
      />
    </>
  );
};
