import cn from 'classnames';
import {
  useCallback,
  useDeferredValue,
  useEffect,
  useMemo,
  useState
} from 'react';
import { ProductCard } from '@gemini/brand/el/ui/organisms/product-card';
import {
  IInlineContentItem,
  ProductTout
} from '@gemini/brand/el/ui/organisms/product-tout';
import { trackProductGridViewed } from '@gemini/shared/services/analytics/events';
import { CartError } from '@gemini/shared/services/cart/cart';
import { useAppContext } from '@gemini/shared/services/configuration/app-client';
import { useTranslations } from '@gemini/shared/services/content/translations';
import {
  ProductType,
  sortProductsByInventoryStatus
} from '@gemini/shared/services/products/catalog';
import { MaxItemError } from '@gemini/shared/ui/molecules/product-cta';
import {
  BreakpointValues,
  getGridColsClassName,
  ResponsiveVirtualGrid
} from '@gemini/shared/ui/molecules/virtual-grid';
import {
  createFilterArray,
  FilterSession,
  IFilter,
  useFilterSession
} from '@gemini/shared/ui/organisms/filter';
import {
  createSDProductFilter,
  FiltersWidgetsProvider,
  IFiltersWidgets
} from '@gemini/shared/ui/organisms/sd-product-filter';
import {
  createSdProductSortFilter,
  FILTER_SORT_NAME
} from '@gemini/shared/ui/organisms/sd-product-grid';
import { Fetcher } from '@gemini/shared/ui/utils/fetcher';
import { useBreakpoint } from '@gemini/shared/ui/utils/hooks';
import { useProductGridData } from '@gemini/shared/ui/utils/products';
import { useGetProducts } from '@gemini/shared/ui/utils/swr';
import { getLogger } from '@gemini/shared/utils/logger';
import { PRODUCT_GRID } from '@gemini/shared/utils/testing';
import { FilterLabel } from './FilterLabel';
import { FilterSelect } from './FilterSelect';
import type {
  IContentBlocks,
  IGridItem,
  IGridItemProduct,
  IGridItemTout,
  IProductGridInlineContentBlockItem,
  IProductGridProps
} from './interfaces';
import { ProductGridFilters } from './ProductGridFilters';

function mergeProductsAndTouts(
  products: ProductType[] | undefined,
  inlineContent: IInlineContentItem[] | undefined
): IGridItem[] {
  const touts = (inlineContent || []).map<IGridItemTout>((item) => ({
    type: 'tout',
    data: item
  }));
  const items = (products || []).map<IGridItemProduct>((item) => ({
    type: 'product',
    data: item
  }));

  touts.forEach((tout) => {
    (items as IGridItem[]).splice(
      Number.parseInt(tout.data.position.data, 10) - 1,
      0,
      tout
    );
  });

  return items as IGridItem[];
}

const DEFAULT_COLS_DESKTOP = 3;
const DEFAULT_COLS_MOBILE = 1;

function getTouts(contentBlocks: IContentBlocks) {
  const { data } = contentBlocks;
  const inlineBlocks =
    data.inlineBlocks ||
    (Object.values(data) as IProductGridInlineContentBlockItem[]);
  const touts: IInlineContentItem[] = [];

  inlineBlocks.forEach(
    (item: IProductGridInlineContentBlockItem, idx: number) => {
      if (
        item.data.data?.inlineBlock?.data?.templates &&
        item.data.data?.inlineBlock?.data?.templates[0]
      ) {
        const tout = {
          colspan: {
            type: 'number',
            data: (idx + 1).toString()
          },
          position: item.data.data.position,
          content: item.data.data.inlineBlock
        };
        touts.push(tout);
      }
    }
  );

  return touts;
}

const FILTER_WIDGETS: IFiltersWidgets = {
  Select: FilterSelect,
  Label: FilterLabel
};

export function ProductGrid(props: IProductGridProps) {
  const { data, productData } = props;
  const { config, contentBlocks } = data;
  const {
    enableFilters,
    filterData,
    hideProductPrice,
    hideSorting,
    mppDefaultCtaOption,
    showSubDisplayName,
    productGridHeader,
    productGridSubheader
  } = config;

  useEffect(() => {
    trackProductGridViewed(productData);
  }, []);

  const translations = useTranslations();
  const { appConfig } = useAppContext();
  const {
    prodcatConfig: { inventoryStatusSorting }
  } = appConfig;

  const [isFiltering, setIsFiltering] = useState<boolean>(false);
  const [allProducts, setAllProducts] = useState<ProductType[]>(productData);
  const [error, setError] = useState<CartError>();

  const inlineContent = useMemo(() => {
    return getTouts(contentBlocks);
  }, [contentBlocks]);
  const hasFilters = Boolean(filterData?.filterSets);
  const showCta = mppDefaultCtaOption !== 'hide_cta';
  const showFilters = enableFilters && hasFilters;
  const showSort = !hideSorting;

  const { filters, filterNames, sortName, filterName } = useMemo(() => {
    const filterArray = createFilterArray();
    let targetFilterNames: string[] = [];
    const targetSortName = FILTER_SORT_NAME;
    let sdFilterName = '';

    if (showFilters) {
      const { name, getInitialFilterData, filter, names } =
        createSDProductFilter({
          filters: config.filters,
          filterData: config.filterData
        });

      sdFilterName = name;

      filterArray.add({
        name: sdFilterName,
        filter,
        getInitialFilterData,
        category: 'sd_filter',
        order: 0
      });
      targetFilterNames = names;
    }

    if (showSort) {
      const { filter: sortFilter, getInitialFilterData } =
        createSdProductSortFilter({
          products: allProducts,
          translations
        });

      filterArray.add({
        name: targetSortName,
        category: 'sort',
        filter: sortFilter as IFilter,
        getInitialFilterData,
        order: 999
      });
    }

    return {
      filterName: sdFilterName,
      filters: filterArray,
      filterNames: targetFilterNames,
      sortName: targetSortName
    };
  }, [allProducts, config, showSort, showFilters]);

  const {
    onChange,
    filtersData,
    onFiltersDataChange,
    filteredItems: products
  } = useFilterSession(filters, allProducts);

  const defaultClearValue = useMemo(() => {
    return JSON.stringify(
      filters.getByName(filterName)?.getInitialFilterData()
    );
  }, [config, filterNames, filters, filterName]);

  const showClearAll = useMemo(() => {
    const compareClearValue = JSON.stringify(filtersData[filterName]);

    return (
      JSON.stringify(defaultClearValue) !== JSON.stringify(compareClearValue)
    );
  }, [filterNames, filtersData]);

  const { isMobile, isDesktop } = useBreakpoint('block md:hidden');

  const [items, setItems] = useState<IGridItem[]>(() =>
    mergeProductsAndTouts(products, inlineContent)
  );

  useEffect(() => {
    setAllProducts(productData);
  }, [productData]);

  useEffect(() => {
    setItems(mergeProductsAndTouts(products, inlineContent));
  }, [products, inlineContent]);

  useEffect(() => {
    setIsFiltering(allProducts.length !== products.length);
    if (inventoryStatusSorting.enabled) {
      setItems(
        mergeProductsAndTouts(
          sortProductsByInventoryStatus(
            products,
            inventoryStatusSorting.statuses
          ),
          inlineContent
        )
      );
    }
  }, [allProducts, products]);

  const onClearAll = useCallback(() => {
    const initialData = filters.getByName(filterName)?.getInitialFilterData();

    onFiltersDataChange({ ...filtersData, [filterName]: initialData });
  }, [defaultClearValue, onFiltersDataChange, filtersData]);

  const bps = config.productGridBreakpoints;

  const cols: BreakpointValues<number> = {
    sm: bps.numColumnsMobile ?? DEFAULT_COLS_MOBILE,
    md: bps.numColumnsDesktop ?? DEFAULT_COLS_DESKTOP,
    lg: bps.numColumnsLargeScreen ?? DEFAULT_COLS_DESKTOP,
    xl: bps.numColumnsExtraLargeScreen ?? DEFAULT_COLS_DESKTOP
  };

  const gridClassName = cn(
    getGridColsClassName(cols.sm, 'sm'),
    getGridColsClassName(cols.md, 'md'),
    getGridColsClassName(cols.lg, 'lg'),
    getGridColsClassName(cols.xl, 'xl')
  );

  const filtersTitle =
    config.filterData?.filtersTitle ??
    translations.elc_product.filters ??
    'Filters';

  const deferredItems = useDeferredValue(items);

  return productData.length === 0 ? null : (
    <div
      data-testid={PRODUCT_GRID}
      className={`mb-0 mx-auto max-w-[1150px] md:mb-30px ${
        productGridHeader ? 'md:mt-[14px]' : 'md:mt-[45px]'
      }`}
    >
      <MaxItemError error={error} onClose={() => setError(undefined)} />
      <>
        {productGridHeader && (
          <h1 className="font-optimadisplaylight mb-0 md:mb-[5px] mx-auto text-navy md:max-w-[768px] text-[40px] tracking-[-.02em] text-center leading-[45px]">
            {productGridHeader}
          </h1>
        )}
        {productGridSubheader &&
          {
            /* @todo add and style the subheader */
          }}
        {isDesktop && (
          <div
            className={cn('hidden md:block border-t border-silver', {
              'md:pt-[4px]': showFilters || showSort,
              'md:pb-1 md:pt-5px': !(showFilters || showSort)
            })}
          ></div>
        )}

        <FiltersWidgetsProvider value={FILTER_WIDGETS}>
          <FilterSession
            filters={filters}
            items={allProducts}
            filteredItems={products}
            onChange={onChange}
            filtersData={filtersData}
            onFiltersDataChange={onFiltersDataChange}
            filterSets={config.filterData?.filterSets}
          >
            <ProductGridFilters
              filtersTitle={filtersTitle}
              filterBy={config.filters?.filterBy ?? false}
              showSort={showSort}
              sortName={sortName}
              showFilters={showFilters}
              filterNames={filterNames}
              count={products.length}
              showClearAll={showClearAll}
              onClearAll={onClearAll}
            />
          </FilterSession>
        </FiltersWidgetsProvider>

        {isMobile && (
          <div className="block border-t md:hidden border-silver"></div>
        )}

        <div className="md:mt-1">
          <ResponsiveVirtualGrid
            count={deferredItems.length}
            cols={cols}
            defaultSize={750}
            overscanCells={2}
            rowsPerCell={3}
          >
            {({ itemStart, itemEnd, style, key, measureElement, lastCell }) => {
              return (
                <div
                  ref={measureElement}
                  key={key}
                  className={cn(
                    'grid gap-0 md:gap-2 absolute top-0 left-0 right-0 align-items-center',
                    gridClassName,
                    lastCell ? null : 'pb-0 md:pb-8'
                  )}
                  style={style}
                >
                  {deferredItems
                    .filter((item) => !isFiltering || item.type !== 'tout')
                    .slice(itemStart, itemEnd)
                    .map((item, idx) => {
                      const index = itemStart + idx;

                      switch (item.type) {
                        case 'product':
                          return (
                            <ProductCard
                              key={`ProductCard-${item.data.productId}-${
                                item.data.skus[0]?.materialCode || index
                              }`}
                              product={item.data}
                              positionIndex={index + 1}
                              showCta={showCta}
                              showPrice={!hideProductPrice}
                              showProductRating={true}
                              showTitle={true}
                              showSubtitle={showSubDisplayName}
                              imagePriority={index < 3}
                              isMobile={isMobile}
                              isDesktop={isDesktop}
                              onError={setError}
                              showShadeNavButtons={isDesktop}
                            />
                          );
                        case 'tout':
                          return (
                            <div
                              key={`ProductTout-${item.data.position.data}`}
                              className={
                                !isFiltering ? 'flex justify-center' : 'hidden'
                              }
                            >
                              <ProductTout tout={item.data} />
                            </div>
                          );
                        default:
                          return null;
                      }
                    })}
                </div>
              );
            }}
          </ResponsiveVirtualGrid>
        </div>
      </>
    </div>
  );
}

export const ProductGridWrapper = (
  props: Omit<IProductGridProps, 'productData'>
) => {
  const { pendingIds, productData, useUpdateProducts } = useProductGridData(
    props.data.config.items
  );

  const getSDProducts = useCallback(
    () => useGetProducts(pendingIds),
    [pendingIds]
  );

  return (
    <Fetcher
      useLoader={getSDProducts}
      onLoad={useUpdateProducts}
      onError={getLogger<false>().logError}
      renderChildren={pendingIds.length === 0}
      renderOnLoad
    >
      {productData.length > 0 ? (
        <ProductGrid {...props} productData={productData} />
      ) : (
        <></>
      )}
    </Fetcher>
  );
};
