import { ComponentType, FC, useCallback, useMemo } from 'react';
import { ProductType } from '@gemini/shared/services/products/catalog';
import { useFilter } from '@gemini/shared/ui/organisms/filter';
import { ISDProductSingleFilterConfig } from '../../IFilterSetFilter';
import { IFilterWidgetProps } from '../../IFilterWidgetProps';
import { ISDProductFilterAggregatorData } from '../../ISDProductFilterAggregator';
import { useFiltersWidgets } from '../../useFiltersWidgets';
import { useSDProductSubFilter } from '../../useSDProductSubFilter';
import { IOption, ISelectProps } from '../../widgets/ISelectProps';
import { SD_PRODUCT_FILTER } from '../constants';
import { IAttributeFilter, IAttributeFilterData } from './attributeFilter';

export interface IAttributeFilterWidgetProps {
  name: string;
  component: ComponentType<ISelectProps>;
}

export const AttributeFilterWidget: FC<IFilterWidgetProps> = ({ name }) => {
  const {
    filteredItems: filteredProducts,
    filterData: mainFilterData,
    filter: mainFilter
  } = useFilter<ProductType, ISDProductFilterAggregatorData>(SD_PRODUCT_FILTER);
  const subFilterInfo = useSDProductSubFilter<IAttributeFilterData>(name);
  const { Select } = useFiltersWidgets();

  if (!subFilterInfo) {
    return null;
  }

  const attributeFilter = subFilterInfo.filter as IAttributeFilter;
  const value = subFilterInfo.filterData?.options;
  const {
    onDataChange,
    filteredItems,
    filterData,
    items: allProducts,
    filter: subFilter
  } = subFilterInfo;
  const items = attributeFilter.filterSets.items;

  const hiddenOptionValuesSet = useMemo(() => {
    const result = new Set<string>();
    items.forEach((i) => {
      const data: IAttributeFilterData = {
        options: [i.key]
      };

      const fConfig: ISDProductSingleFilterConfig<IAttributeFilterData> = {
        items: allProducts,
        data,
        item: null as unknown as ProductType
      };

      const found = allProducts.some((p) => {
        fConfig.item = p;

        return attributeFilter(fConfig);
      });
      if (!found) {
        result.add(i.key);
      }
    });

    return result;
  }, [allProducts]);

  const partialFilteredItems = useMemo<ProductType[]>(() => {
    const otherFiltersData: ISDProductFilterAggregatorData = {
      filterData: {
        ...(mainFilterData as ISDProductFilterAggregatorData).filterData
      }
    };
    const subFilterData: IAttributeFilterData = {
      options: []
    };
    otherFiltersData.filterData[name] = subFilterData;

    const otherProducts =
      mainFilter?.filter?.({
        items: allProducts,
        data: otherFiltersData,
        name: SD_PRODUCT_FILTER
      }) ?? [];

    return otherProducts as ProductType[];
  }, [name, mainFilterData, mainFilter, filteredProducts]);

  const options = useMemo(() => {
    const result: IOption[] = [];
    const visitedSet: Set<string> = new Set();

    items.forEach((i) => {
      if (!hiddenOptionValuesSet.has(i.key) && !visitedSet.has(i.key)) {
        visitedSet.add(i.key);

        const data: IAttributeFilterData = {
          options: [i.key]
        };

        // reuse the filter object
        const fConfig: ISDProductSingleFilterConfig<IAttributeFilterData> = {
          item: null as unknown as ProductType,
          data,
          items: partialFilteredItems
        };

        const disabled = !partialFilteredItems.some((p) => {
          fConfig.item = p;

          return subFilter(fConfig);
        });

        result.push({
          value: i.key,
          label: i.label,
          disabled
        });
      }
    });

    return result;
  }, [items, filteredItems, filterData, filteredProducts]);

  const onChange = useCallback(
    (values: string[]) => {
      const newData: IAttributeFilterData = {
        options: values
      };
      onDataChange(newData);
    },
    [onDataChange]
  );

  return options.length ? (
    <Select
      value={value}
      options={options}
      onChange={onChange}
      label={attributeFilter.filterSets.label}
      name={name}
    />
  ) : null;
};
