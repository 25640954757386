import type { FC, PropsWithChildren } from 'react';
import {
  FiltersWidgetsContext,
  IFiltersWidgets
} from './FiltersWidgetsContext';

export type IFiltersWidgetsProviderProps = PropsWithChildren<{
  value: IFiltersWidgets;
}>;

export const FiltersWidgetsProvider: FC<IFiltersWidgetsProviderProps> = ({
  value,
  children
}) => (
  <FiltersWidgetsContext.Provider value={value}>
    {children}
  </FiltersWidgetsContext.Provider>
);
