import type { IFilterSets } from '@gemini/shared/services/products/next-sd-prodcat';

interface IActiveFilters {
  [k: string]: string[];
}

const FILTER_DELIMITTER = ',';

const convertFilterValueLabel = (label: string): string => {
  const transformed = label.toLowerCase().trim();

  return transformed.replace(/ /g, '-');
};

const convertFilterKeyLabel = (label: string): string =>
  label.replace(/_/g, '-');

const FilterService = (
  filterData: any[],
  filterSets: IFilterSets[] | undefined
) => {
  const setToUrl = (activeFilters: IActiveFilters) => {
    const qsParams = new URLSearchParams(window.location.search);

    Object.keys(filterData).forEach((k) => {
      const cKey = convertFilterKeyLabel(k);
      const vals = k in activeFilters ? activeFilters[k] : [];

      qsParams.delete(cKey);

      if (vals.length) {
        // The delimiter here gets encoded by URLSearchParams,
        // but it doesn't seem to matter
        qsParams.set(cKey, vals.join(FILTER_DELIMITTER));
      }
    });

    const newQs = qsParams.toString();

    window.history.replaceState(
      null,
      '',
      window.location.pathname + (newQs.length ? `?${newQs}` : ``)
    );
  };

  const getAppliedFiltersQueryString = () => {
    const activeFilters: IActiveFilters = {};

    for (const [key, value] of Object.entries(filterData)) {
      value?.options.forEach((option: string) => {
        if (filterSets instanceof Array) {
          filterSets.forEach((filter_sets: IFilterSets) => {
            filter_sets.items.forEach((team) => {
              if (team.key === option) {
                if (!(key in activeFilters)) {
                  activeFilters[key] = [];
                }

                activeFilters[key].push(convertFilterValueLabel(team.label));
              }
            });
          });
        }
      });
    }

    setToUrl(activeFilters);
  };

  getAppliedFiltersQueryString();
};

const readParams = (filterSets: IFilterSets[] | undefined) => {
  type FilterData = { filterData: Record<string, unknown> };

  const filterData: FilterData = { filterData: {} };
  const url_string = window.location.href;
  const url = new URL(url_string);

  filterSets?.forEach((filter_sets: IFilterSets) => {
    const key_label = filter_sets.key;
    const params = url.searchParams.get(convertFilterKeyLabel(key_label));
    let values: string[] = [];

    if (params) {
      const params_array = params.split(FILTER_DELIMITTER);

      params_array.forEach((option: string) => {
        filter_sets.items.forEach((team) => {
          if (convertFilterValueLabel(team.label) === option) {
            values.push(team.key);
          }
        });
      });
    }

    filterData.filterData[key_label] = { options: values };
    values = [];
  });

  return filterData;
};

export { FilterService, readParams, FILTER_DELIMITTER };
