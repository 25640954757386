import { useCallback, useMemo } from 'react';
import { IOption } from './IMultiSelectProps';

export interface IUseMultiSelect {
  value: string[];

  onChange?(value: string[]): void;
}

export const useMultiSelect = ({ value, onChange }: IUseMultiSelect) => {
  const selectedSet = useMemo(() => new Set<string>(value), [value]);

  const onOptionClick = useCallback(
    (o: IOption) => {
      if (o.disabled) {
        return;
      }

      const newSet = new Set(selectedSet);

      if (selectedSet.has(o.value)) {
        newSet.delete(o.value);
      } else {
        newSet.add(o.value);
      }

      onChange?.(Array.from(newSet));
    },
    [selectedSet, onChange]
  );

  return {
    selectedSet,
    selectedCount: selectedSet.size,
    onOptionClick
  };
};
