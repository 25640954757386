import { ProductType } from '@gemini/shared/services/products/catalog';
import type {
  ISortAcceptFn,
  ISortConfig,
  ISortFn
} from '@gemini/shared/services/products/product-sort';
import type {
  IFilter,
  IItemsFilterConfig
} from '@gemini/shared/ui/organisms/filter';

export interface ISortFilter {
  sort: ISortFn<ProductType>;
  accept?: ISortAcceptFn<ProductType>;
  name: string;
}

export interface ICreateProductFilterSortConfig {
  sortFilters: ISortFilter[];
  getDefaultIndex: ISortConfig<ProductType>['getDefaultIndex'];
}

export interface IProductSortFilterData {
  sortName?: string;
}

export interface IProductSortFilter
  extends IFilter<ProductType, IProductSortFilterData> {
  sortFilters: ISortFilter[];
}

export function createProductSortFilter({
  sortFilters,
  getDefaultIndex
}: ICreateProductFilterSortConfig): IFilter<
  ProductType,
  IProductSortFilterData
> {
  const fn: IFilter<ProductType, IProductSortFilterData> = ({
    name,
    items,
    data
  }: IItemsFilterConfig<ProductType, IProductSortFilterData>) => {
    const sortFilter = sortFilters.find((f) => f.name === data.sortName);

    return sortFilter
      ? sortFilter.sort({ items, getDefaultIndex })
      : items.slice();
  };
  (fn as unknown as IProductSortFilter).sortFilters = sortFilters;

  return fn;
}

export function getProductSortInitialFilterData(): IProductSortFilterData {
  return {
    sortName: undefined
  };
}

export function createGetDefaultIndexFn(products: ProductType[]) {
  const productDataMap = products.reduce((acc, product, idx) => {
    acc.set(product.productId, idx);

    return acc;
  }, new Map<string, number>());

  return (item: ProductType): number => {
    return productDataMap.get(item.productId) ?? -1;
  };
}
