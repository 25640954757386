import { FC, useCallback } from 'react';
import { useTranslations } from '@gemini/shared/services/content/translations';
import { Modal } from '@gemini/shared/ui/organisms/modal';
import { SDProdcutFilter } from '@gemini/shared/ui/organisms/sd-product-filter';
import { IProductGridFiltersProps } from './IProductGridFiltersProps';
import styles from './ProductGridFiltersModal.module.scss';

export interface IProductGridFiltersModalProps
  extends IProductGridFiltersProps {
  isOpen: boolean;

  onClose(): void;
}

export const ProductGridFiltersModal: FC<IProductGridFiltersModalProps> = ({
  isOpen,
  onClose,
  filterBy,
  filterNames,
  showClearAll,
  onClearAll
}) => {
  const translations = useTranslations();

  const clearAllLabel =
    translations.elc_product.filter_clear_all ??
    translations.elc_general.clear_all ??
    'Clear All';
  const filterByLabel = translations.elc_product.filter_by_label ?? 'Filter by';
  const applyFiltersLabel =
    translations.elc_product.apply_filters ?? 'Apply filters';

  const onApplyFiltersClick = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <Modal
      isOpen={isOpen}
      overlay={true}
      overflow
      windowScroll={false}
      overlayColor="bg-black"
      overlayOpacity="opacity-90"
      width="w-full"
      height="h-full"
      closeIconPositionClasses={`top-[3px] right-[-1px] ${styles['close-button']}`}
      closeModal={onClose}
      iconConfig={{
        height: 30,
        width: 30,
        fill: '#A4A4AB'
      }}
    >
      <div className="p-[20px] flex flex-col text-[12px] h-full">
        {filterBy && (
          <div className="pt-[10px] pb-[20px] font-akzidenzgrotesk text-[14px] tracking-[1.25px] uppercase font-bold leading-[16px]">
            {filterByLabel}
          </div>
        )}
        <div className="grow -mx-[20px] px-[20px] overflow-auto">
          {filterNames.map((fn) => (
            <div key={fn}>
              <SDProdcutFilter name={fn} />
            </div>
          ))}
        </div>
        <div className="text-center">
          {showClearAll && (
            <button
              className="appearance-none w-full h-[40px] py-[10px] px-[20px] uppercase underline font-akzidenzgrotesk text-[12px] text-gray-500 cursor-pointer tracking-[.15em] leading-[1]"
              onClick={onClearAll}
            >
              {clearAllLabel}
            </button>
          )}
          <button
            className="appearance-none w-[80%] text-center mt-[10px] mb-[50px] bg-deep-cove text-white uppercase font-akzidenzgrotesk text-[16px] tracking-[0.5px] h-[40px] cursor-pointer"
            onClick={onApplyFiltersClick}
          >
            {applyFiltersLabel}
          </button>
        </div>
      </div>
    </Modal>
  );
};
