import { FC, useCallback, useMemo } from 'react';
import { useTranslations } from '@gemini/shared/services/content/translations';
import type { IDropdownProps } from '@gemini/shared/ui/organisms/sd-product-grid';
import { useBreakpoint } from '@gemini/shared/ui/utils/hooks';
import { IOption, SelectDropdown } from './SelectDropdown';

export const SortDropdown: FC<IDropdownProps> = ({
  options,
  disabled,
  value,
  onChange
}) => {
  const {
    elc_product: { sort_by: sortByLabel }
  } = useTranslations();

  const DEFAULT_SELECTED = useMemo<IOption>(
    () => ({
      label: sortByLabel ?? 'Sort by',
      value: ''
    }),
    [sortByLabel]
  );

  const { isMobile } = useBreakpoint('block lg:hidden');

  const list = useMemo(() => {
    if (isMobile) {
      return [DEFAULT_SELECTED, ...options];
    }

    return options;
  }, [options, isMobile, DEFAULT_SELECTED]);

  return (
    <SelectDropdown
      value={value}
      options={list}
      placeholder={DEFAULT_SELECTED.label}
      label={DEFAULT_SELECTED.label}
      onChange={onChange}
      dropdownPosition="right"
      selectClassName="text-right"
      dropdownClassName="w-[175px]"
    />
  );
};
